html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .container {
    max-width: 960px;
  }
  
  .pricing-header {
    max-width: 700px;
  }
  
  .card-deck .card {
    min-width: 220px;
  }
  